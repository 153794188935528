import "./Footer.css";
import ct from "../../icons/crobotlogo.png";
import {
  FaFacebook,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaLink,
  FaMail
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="flex-box footer_container">
      <div className="flex-box top">
        <div className="flex-box aligned">
          <h2>Mailing Address</h2>
          <p>
            Crobot Technologies Private Limited <br />
            No: 68, Kadamban Street, Madurai Shree Meenatchi Nagar, Valasaravakkam, <br />
            Chennai, Tamil Nadu, India - 600087.
          </p>
        </div>
        <div className="flex-box aligned">
          <h2>We Care and We Assist</h2>
          <div id="logo_footer">
            <img src={ct} />
          </div>
        </div>
        <div className="flex-box aligned">
          <h2>Follow Us On</h2>
          <div className="flex-box logodiv">
          <a href=""><FaFacebook /></a>
            <a href="https://www.linkedin.com/company/crobottech/"><FaLinkedinIn /></a>
            <a href=""><FaInstagram /></a>
            <a href="https://www.linkedin.com/company/crobottech/"><FaYoutube /></a>
          </div>
          <div className="email">
            <h3>Email</h3>
            <p>info@crobottech.com</p>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div>
          <p>COPYRIGHTS © 2023 CROBOT TECHNOLOGIES PVT. LTD.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

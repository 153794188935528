import React, { useEffect } from 'react'
import { useOutletContext, Navigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

const DashLogout = () => {

  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, [])
  
  return (
    <Navigate to="/signin" />
  )
}

export default DashLogout
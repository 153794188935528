import React, { useEffect, useState } from 'react'

import './SideBar.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUserCircle, faHouse, faGamepad, faCartShopping, faUser, faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const SideBar = ({ sentUser, getUserDetails }) => {

  const user = JSON.parse(sentUser)
  // const [isCollapsed, setIsCollapsed] = useState(true)
  const [selected, setSelected] = useState(null)

  // console.log(sentUser)
  const currentUser = JSON.parse(sentUser)
  // console.log(currentUser)
  // console.log(`Current User Type ${currentUser}`)

  const MiniCards = ({ icon, title, setSelected, selected }) => {

    return (
      <Link to={`/dashboard/${title}`}>
        <FontAwesomeIcon icon={icon} style={{ color: (selected === title) ? 'var(--secondary-color)' : 'inherit' }} onClick={() => setSelected(title)} />
      </Link>
    )
  }

  const MenuCards = ({ icon, title, setSelected, selected }) => {

    return (
      <div className="fl-col-c-c menu-expanded" style={{ color: (selected === title) ? 'var(--secondary-color)' : 'inherit' }} onClick={() => setSelected(title)} >
        <Link to={`/dashboard/${title}`}>
          <FontAwesomeIcon icon={icon} />&nbsp;&nbsp;{title}
        </Link>
      </div>
    )
  }

  const changeSelected = (target) => {
    setSelected(target)
  }

  const loc = window.location.pathname
  useEffect(() => {
    const target = loc.split("/")[2]
    // console.log(target)
    changeSelected(target)
  }, [loc])

  return (
    <div className='fl-col-c-c sidebar-container'>
      {/* <div className="fl-col-c-c ham-icon" style={{ justifyContent: isCollapsed ? 'center' : 'end' }}>
        {isCollapsed ? <FontAwesomeIcon icon={faBars} onClick={() => setIsCollapsed(!isCollapsed)} /> : <FontAwesomeIcon icon={faXmark} onClick={() => setIsCollapsed(!isCollapsed)} />}
      </div> */}
      <div className="fl-col-c-c ham-icon">
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div className="fl-col-c-c regular-heading">
        <FontAwesomeIcon icon={faUserCircle} />{user.name}
      </div>
      <div className="fl-col-c-c sidebar-content-mobile">
        <>
          <MiniCards icon={faHouse} title={"home"} setSelected={setSelected} selected={selected} />
          <MiniCards icon={faGamepad} title={"operate"} setSelected={setSelected} selected={selected} />
          <MiniCards icon={faCartShopping} title={"subscription"} setSelected={setSelected} selected={selected} />
          <MiniCards icon={faUser} title={"profile"} setSelected={setSelected} selected={selected} />
          <MiniCards icon={faDoorOpen} title={"logout"} setSelected={setSelected} selected={selected} />
        </>
      </div>
      <div className="fl-col-c-c sidebar-content">
        <>
          <MenuCards icon={faHouse} title={"home"} setSelected={setSelected} selected={selected} />
          <MenuCards icon={faGamepad} title={"operate"} setSelected={setSelected} selected={selected} />
          <MenuCards icon={faCartShopping} title={"subscription"} setSelected={setSelected} selected={selected} />
          <MenuCards icon={faUser} title={"profile"} setSelected={setSelected} selected={selected} />
          <MenuCards icon={faDoorOpen} title={"logout"} setSelected={setSelected} selected={selected} />
        </>
      </div>
    </div>
  )
}

export default SideBar
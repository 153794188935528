import React from 'react'

import './Product.css'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import Ubot from '../../assets/ubot/ubot1.webp'
import GBot from '../../assets/ubot/amr.webp'
import AriyanBot from '../../assets/ariyan/ariyan2.webp'

const Product = () => {
  return (
    <>
      <Header />
      <div className="fl-col-c-c product__container">
        <div className="fl-col-c-c product__cover column__align__left">
          <img src={AriyanBot} alt="Ariyan, the Educational aid Robot" />
          <div className="fl-col-c-c product__text">
            <div className="product__title">Ariyan</div>
            <div className="product__description">
              <strong>Introducing Ariyan: The Ultimate Robotics Learning and Research Companion</strong>
              <br /><br />
              Ariyan, your gateway to robotics exploration, is a highly customizable marvel designed for students and researchers. Boasting 3D vision capabilities, a potent GPU, and precision actuators, Ariyan promises exceptional mobility and computational power. It comes preloaded with ROS and dependencies, enabling immediate experimentation. Ariyan's commercial affordability ensures accessibility, making it a budget-friendly choice.
              <br /><br />
              Its 3D vision capabilities allow for intricate exploration in fields like object recognition and scene reconstruction. Powered by a robust GPU, Ariyan is a computational powerhouse, ideal for deep learning and artificial intelligence research. Its high-quality actuators deliver precision mobility, offering an excellent platform for robotics and kinematics experiments. Ariyan seamlessly integrates with the Robot Operating System (ROS) and essential dependencies, ensuring a hassle-free setup for research and development. What sets Ariyan apart is its budget-friendly nature, making the world of robotics accessible to aspiring learners and resourceful researchers alike.
              <br /><br />
              Ariyan is your passport to limitless possibilities in robotics, AI, and beyond. Whether you're a student shaping your future or a researcher pushing boundaries, Ariyan is your ultimate companion for learning and discovery.
            </div>
            <div className="button__buyrobot">
              <a href="">Buy Now!</a>
            </div>
          </div>
        </div>
        <div className="fl-col-c-c product__cover column__align__right">
          <img src={Ubot} alt="Ubot" />
          <div className="fl-col-c-c product__text">
            <div className="product__title">Ubot</div>
            <div className="product__description">
              <strong>Introducing UBot: The Ultimate Telepresence Robot for Enhanced Operations</strong>
              <br /><br />
              UBot, short for UserBot, is a cutting-edge robotic solution designed to revolutionize telepresence operations across a wide range of industries. Built upon the robust ROS Noetic (ROS 1) framework, UBot seamlessly integrates advanced features like obstacle avoidance, ensuring smooth navigation and enhanced safety in dynamic environments.
              <br /><br />
              With its exceptional versatility, UBot empowers businesses in various sectors, including warehouses, hospitals, fairs, expos, and beyond, to extend their reach and capabilities. By leveraging UBot's telepresence capabilities, remote users can effortlessly navigate and interact with their surroundings, facilitating effective communication, remote collaboration, and streamlined operations.
              <br /><br />
              With its state-of-the-art technology and unparalleled adaptability, UBot stands at the forefront of robotics innovation, providing a reliable and efficient solution for diverse applications, empowering businesses to optimize their operations and unlock new possibilities.
            </div>
            <div className="button__buyrobot">
              <a href="">Buy Now!</a>
            </div>
          </div>
        </div>
        <div className="fl-col-c-c product__cover column__align__left">
          <img src={GBot} alt="Gas Sensor Enabled Robot" />
          <div className="fl-col-c-c product__text">
            <div className="product__title">Gas Sensor Enabled Robot</div>
            <div className="product__description">
              <strong>Introducing GasBot: The Intelligent Gas Detection Robot for Warehouse Safety</strong>
              <br /><br />
              GasBot is an innovative robotic solution tailored specifically for warehouse environments, equipped with advanced gas detection capabilities. With its integrated gas sensor, GasBot serves as a vigilant sentinel, capable of detecting and alerting against a wide range of gases present in the surrounding environment, ensuring the utmost safety and mitigating potential hazards.
              <br /><br />
              Designed with efficiency in mind, GasBot incorporates cost-effective components and leverages 3D printing technology, making it an affordable and accessible solution for warehouse operators. Its remote control functionality allows operators to effortlessly navigate GasBot through the warehouse, monitoring gas levels and swiftly responding to any abnormalities.
              <br /><br />
              By harnessing GasBot's intelligent gas detection capabilities, warehouse operators can proactively identify and address potential gas-related risks, promoting a secure working environment for employees and safeguarding valuable assets. GasBot exemplifies the synergy between robotics and safety, delivering a reliable, low-cost solution that brings peace of mind to warehouse operations.
            </div>
            <div className="button__buyrobot">
              <a href="">Buy Now!</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Product
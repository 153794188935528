import { useState, useEffect } from "react";
import "./ElasticCarousel.css";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

import img0 from "../../assets/ubot/DSC_0068.webp"
import img1 from "../../assets/carousel_images/img1.webp"
import img2 from "../../assets/carousel_images/img2.webp"
import img3 from "../../assets/carousel_images/img3.webp"
// import img4 from "../../assets/carousel_images/img4.webp"
import img5 from "../../assets/carousel_images/img5.webp"
import img6 from "../../assets/carousel_images/img6.webp"
import img7 from "../../assets/carousel_images/img7.webp"
import img8 from "../../assets/carousel_images/img8.webp"
import img9 from "../../assets/carousel_images/img9.webp"

const ElasticCarousel = () => {
  // const array_img = [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9];
  const array_img = [img0, img1, img2, img3, img5, img6, img7, img8, img9];
  const [currentIndex, updateIndex] = useState(0);
  const goToPrev = () => {
    const target = currentIndex - 1
    if (target < 0) {
      updateIndex(array_img.length - 1)
    }
    else {
      updateIndex(target)
    }
  }

  const goToNext = () => {
    const target = currentIndex + 1
    if (target > array_img.length - 1) {
      updateIndex(0)
    }
    else {
      updateIndex(target)
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      goToNext()
    }, 3000)
    return () => clearInterval(timer)
  })

  return (
    <div id="elastic_carousel">
      <div id="gallery">Gallery</div>
      <div className="carousel" >
        <div className="slider">
          <FaArrowLeft className='slider-icons' onClick={goToPrev} />
          <img src={array_img[currentIndex]} alt="Photos of Ubot" />
          <FaArrowRight id='align-last' className='slider-icons' onClick={goToNext} />
        </div>
        <div className="slider-dot-container">
          {array_img.map((array_img, i) => {
            return (
              <div className="slider-dot" onClick={() => updateIndex(i)} id={(currentIndex === i) ? 'dot-active': ''}></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ElasticCarousel;

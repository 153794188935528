import styled from "styled-components";

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	paddingtop: 10px;
	paddingbottom: 10px;
	padding: 0.5rem 1rem;
	margin: auto;
`;

export const ModalBackdrop = styled.div`
	background: rgba(0, 0, 0, 0.5);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
`;

export const ModalContent = styled.div`
	background: white;
	border-radius: 1rem;
	left: 50%;
	margin: auto;
	padding: rem;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
`;

import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

// import { useAuth } from "./context/AuthContext";

import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import UpcomingEvents from "./UpcomingEvents";
import RoboDashboard from './pages/RoboDashboard/RoboDashboard'
import SignIn from "./pages/SignIn/SignIn";
import SignupSuccess from "./components/SignupSuccess/SignupSuccess";
import NotFound from "./pages/NotFound/NotFound";

import Dashboard from "./pages/Dashboard/Dashboard";

import DashDefault from "./components/DashboardComp/DashDefault";
import DashSubscribe from "./components/DashboardComp/DashSubscribe";
import DashProfile from "./components/DashboardComp/DashProfile";
import DashOperate from "./components/DashboardComp/DashOperate";
import DashLogout from "./components/DashboardComp/DashLogout";

function App() {

	// const { currentUser, getUserDetails } = useAuth()

	// useEffect(() => {
	// 	const funcEx = async () => {
	// 		console.log(`Current user is ${currentUser}`)
	// 		await getUserDetails(currentUser.id, currentUser.token)
	// 	}
	// 	funcEx()
	// }, [])


	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Navigate to='/home' />} />
				<Route path="/home" element={<Home />} />
				<Route path="/product" element={<Product />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/control" element={<RoboDashboard />} />
				<Route path="/signin" element={<SignIn />} />
				<Route path="/dashboard" element={<Dashboard />} >
					<Route index element={<DashDefault/>} />
					<Route path="home" element={<DashDefault/>} />
					<Route path="operate" element={<DashOperate/>} />
					<Route path="subscription" element={<DashSubscribe/>} />
					<Route path="profile" element={<DashProfile/>} />
					<Route path="logout" element={<DashLogout/>} />
				</Route>
				{/* <Route path="/profile" element={<Profile />} /> */}
				<Route path="/upcoming" element={<UpcomingEvents />} />
				<Route path="/email_verification" element={<SignupSuccess />} />
				<Route path="/*" element={<NotFound />} />
			</Routes>
		</div>
	);
}

export default App;

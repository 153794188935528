import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import SignUp from "./SignUp";

import "./SignIn.css";

import { TextField, FormControl, InputLabel, Button, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material'

import loginPage from '../../assets/login_page.svg'

function SignIn() {
	// const navigate = useNavigate()

	const [formErrors, setFormErrors] = useState(null);
	const [isSubmit, setIsSubmit] = useState(false);

	const [signup, setSignup] = useState(false)
	const [signin, setSignin] = useState(true)
	const [userDetails, setUserDetails] = useState({
		email: null,
		password: null,
		token: localStorage.getItem("crobottoken")
	})

	const { logIn, signUp, currentUser, getUserDetails, errorContext } = useAuth();

	const signInUser = async (e) => {
		e.preventDefault()
		await logIn(userDetails.email, userDetails.password).then(() => {
			setFormErrors(errorContext);
		});
		console.log(formErrors);
	}

	const [showPassword, setShowPassword] = useState(false)

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleInputChange = (e) => {
		setUserDetails(prev => ({ ...prev, [e.target.name]: e.target.value }))
	}

	useEffect(() => {
		const funcEx = async (parsedItem) => {
			await getUserDetails(parsedItem.id, parsedItem.token).then(() => {
				console.log(currentUser)
			})
		}
		console.log(currentUser)
		const obtainedItem = sessionStorage.getItem("crobot")
		const parsedItem = JSON.parse(obtainedItem)
		if (parsedItem !== null) {
			funcEx(parsedItem)
		}
	}, [currentUser])

	return (
		<>
			<Header transparent={false} />
			{signup && <SignUp showPassword={showPassword} handleMouseDownPassword={handleMouseDownPassword} handleClickShowPassword={handleClickShowPassword} setSignin={setSignin} setSignup={setSignup} signUp={signUp} />
			}
			{signin && (currentUser !== null ?
				<Navigate to="/dashboard/home" />
				:
				<div className="signin_container">
					<img src={loginPage} alt="Login page" id='loginpage-img' />
					<div className="form-wrapper">
						<div className="log-sign-heading">Welcome Back!</div>
						<TextField
							required
							id="username_input"
							label="Email ID"
							margin="normal"
							style={{ width: '90%' }}
							name="email"
							onChange={handleInputChange}
						/>
						<FormControl required sx={{ m: 1, width: '25ch' }} variant="outlined" style={{ width: '90%' }}>
							<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
											id="eye-button"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label="Password"
								name="password"
								onChange={handleInputChange}
							/>
						</FormControl>
						{formErrors && <span id='error_display'>{formErrors}</span>}
						<Button onClick={signInUser} id='signin-button'>Sign In</Button>
						<div id="signupbanner">
							New to CROBOT? Sign up <span style={{ fontWeight: '600', cursor: 'pointer' }} onClick={() => { setSignup((prev) => !prev); setSignin((prev) => !prev) }}>here!</span>
						</div>
					</div>
				</div>
			)}
			<Footer />
		</>
	);
}

export default SignIn;

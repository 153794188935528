import React from "react";
// import Yellow_line from "../../components/Yellowline/Yellow_line";

import "./Service.css";

import rent from "../../icons/rent.svg";
import getbot from "../../icons/getbot.svg";
import operate from "../../icons/operate.svg";
import subscription from "../../icons/subscription.svg";
import feedback from "../../icons/feedback.svg";
import maintenance from "../../icons/maintenance.svg";

function Service(props) {
  return (
    <div className="services">
      <div style={{ background: props.color }}>
        <img src={props.icon} loading={"lazy"} alt="icon-images"/>
      </div>
      <h3>{props.title}</h3>
      <span>{props.description}</span>
    </div>
  );
}

export default function OurServices() {
  return (
    <div className="services_container">
      <div className="services_heading">Our Services</div>
      <div id="services">
        <div className="service-cards">  
          <Service
            title="GET A TELEBOTZ"
            color="rgba(1, 45, 112, 0.29)"
            icon={getbot}
            description="Our Telebotz helps you save your energy and expense to travel to important events!"
          />
          
          <Service
            title="RENT"
            color=""
            icon={rent}
            description="Easy renting service to enable your distant interactions simple and accessible"
          />
        
          <Service
            title="OPERATE YOUR BOT"
            color="rgba(101, 176, 88, 0.29)"
            icon={operate}
            description="Operate your Telebotz from any location with the help of our dedicated webapp. "
          />
        
          <Service
            title="MAINTENANCE AND REPAIR"
            color=""
            icon={maintenance}
            description="We will provide all sorts of maintenance and repair for your Telebotz"
          />
          
          {/* <Service
					title="MAINTENENCE AND REPAIR"
					color=""
					icon={maintenance}
					description='We will provide all sorts of maintenance and repair for your Telebotz, because "We Care, and We Assist"'
				/> */}
          
          <Service
            title="FEEDBACK"
            color="rgba(101, 176, 88, 0.29)"
            icon={feedback}
            description="We care atmost about the feedback that we receive from our customers"
          />
          
          <Service
            title="BUY SUBSCRIPTION"
            color="rgba(1, 45, 112, 0.29)"
            icon={subscription}
            description="One simple subscription helps you attend and conduct various events"
          />
         
        </div>
      </div>
    </div>
  );
}

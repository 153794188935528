import { useState } from "react";

import Header from "../../components/Header/Header";
import OurServices from "../Services/OurServices";
import ElasticCarousel from "../../components/Carousel/ElasticCarousel";
import AboutUs from "../../components/About/AboutUs";
import Footer from "../../components/Footer/Footer";

import fullBanner from "../../assets/ubot/DSC_0015.webp"

import './Home.css'
import Chatsection from "../../components/ChatWithFounder/Chatsection";

function Home() {
	
	const [transparent, setTransparent] = useState(true)

	window.addEventListener('scroll', () => {
		if (((window.scrollY / window.innerHeight) * 100) > 90) {
			setTransparent(false)
		}
		else {
			setTransparent(true)
		}
	})


	return (
		<>
			<Header navigation1="Dashboard" navigation2="Profile" threeBars="true" transparent={transparent}/>
			<div id="first100">
				<img src={fullBanner} alt="UBot" />
				<div className="captivating-text">
					<h1>Introducing you to our <span style={{color: `var(--supplementary-color)`}}>first successful</span> product, a telepresence mobile robot with semi-autonomous / autonomous navigation capabilites</h1>
					<br />
					<h1 style={{color: `var(--secondary-color)`}}>UBot</h1>
				</div>
			</div>
			<OurServices />
			<ElasticCarousel />
			<AboutUs />
			<Chatsection />
			<Footer />
		</>
	);
}
export default Home;

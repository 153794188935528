import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'

import './contact.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const Contact = () => {

    const { putContactQuery } = useAuth()

    const [fields, setFields] = useState({
        name: null,
        email: null,
        country: null,
        query: null
    });

    const submitQuery = async (e) => {
        const token = localStorage.getItem('queryToken');
        e.preventDefault();
        if (token === null) {
            if (fields.name !== null && fields.email !== null && fields.country !== null && fields.query !== null) {
                await putContactQuery(fields).then((res) => {
                    localStorage.setItem('queryToken', "exist");
                });
            }
        }
        else {
            console.log("Already submitted")
        }
    }

    const handleInputChange = (e) => {
        setFields(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    return (
        <>
            <Header />
            <div className='fl-col-c-c contactus__container'>
                <div className="contactus__heading">
                    Let's stay in touch!
                </div>
                <div className="fl-col-c-c contactus__form">
                    <h2>Contact Us</h2>
                    <div className="contactus__form__content">
                        <div className="fl-col-c-c contactus__form__field">
                            <div className="contactus__form__label">Name :</div>
                            <input className="contactus__form__input" name="name" onChange={handleInputChange}></input>
                        </div>
                        <div className="fl-col-c-c contactus__form__field">
                            <div className="contactus__form__label">Email Id :</div>
                            <input className="contactus__form__input" name="email" onChange={handleInputChange}></input>
                        </div>
                        {/* <div className="fl-col-c-c contactus__form__field">
                            <div className="contactus__form__label">Mobile Number :</div>
                            <input className="contactus__form__input"></input>
                        </div> */}
                        <div className="fl-col-c-c contactus__form__field">
                            <div className="contactus__form__label">Country :</div>
                            <input className="contactus__form__input" name="country" onChange={handleInputChange}></input>
                        </div>
                        <div className="fl-col-c-c contactus__form__field">
                            <div className="contactus__form__label">Query :</div>
                            <textarea className="contactus__form__input" maxLength={1000} name="query" onChange={handleInputChange}></textarea>
                        </div>
                    </div>
                    <div className="contactus__form__button">
                        <button onClick={submitQuery}>Submit</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact
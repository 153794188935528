import './AboutUs.css'

function AboutUs(){
    return(
        
        <div className="aboutus_container">
            <div className="aboutus_heading">About Us</div> 
            <div className ="aboutus_content" id="about_us">
                <div className="aboutus_paragraph" style={{color: "black"}}>

                    <strong>CROBOT Technologies Private Limited (CTPL)</strong> is a startup company founded in 2021
                    with an aim to provide automation solutions in the area of robotics. CTPL have
                    a line of products in the area of mobile robots for various applications.
                    <br /><br />
                    {/* <h3 style={{color: `var(--secondary-color)`}}>UBot</h3>  */}
                    {/* <br /> */}
                    The advent of the Internet of Things has brought about a significant revolution, enabling individuals worldwide to engage in multitasking activities across the globe while stationed within the confines of their offices. Under such circumstances, there arise situations where individuals are compelled to attend to multiple tasks at distant locations within a single day. Unfortunately, the associated logistical overheads incurred during these visits tend to be prohibitively high, not to mention the considerable fatigue that accompanies extensive travels.
                    <br /><br />
                    To address these challenges, CTPL has developed and manufactured a groundbreaking solution known as Ubot. Ubot is an innovative telepresence robot meticulously designed and crafted by CTPL, boasting remarkable capabilities that position it as a comprehensive one-stop solution. Notably, Ubot can be conveniently controlled for navigation purposes through the use of a mobile phone. Moreover, Ubot facilitates seamless interaction between its user and individuals present within its environment through advanced voice and video interfaces.
                    <br /><br />
                    The adoption of Ubot presents numerous advantages for users, including the conservation of valuable energy resources, substantial savings on expenses associated with travel, and a streamlined approach to various logistical considerations. By leveraging the unparalleled capabilities of Ubot, individuals can transcend the limitations of physical presence, thereby enhancing productivity and operational efficiency while mitigating the challenges posed by distance and geographical constraints.
                    {/* The revolution on internet of things has enabled everyone of us to do multi-tasking
                    across the globe sitting in our office. In this scenario, there are situations where
                    we may be compelled to attend more than two tasks in a day at distant locations.
                    Additionally, the overheads on the logistics incurred during such visits are too
                    high and also these travels introduce a lot of fatigue. Telebotz manufactured by
                    CTPL serves as a one stop solution. Telebotz is a telepresence robot designed
                    and manufactured by CTPL that can be controlled for navigation using your
                    mobile phone. Telebotz also enables the user to interact with people in its
                    environment through voice and video interface. The user can save on energy,
                    expenses due to travel and other logistics. */}

                    {/*  Write this in the buying page!!!!!!

                    <h3 style={{color: "#012D70"}}>OUR OFFER ALONG WITH TELEBOTZ</h3>
                    <ol>
                        <li>A dedicated webapp powered by CROBOT Technologies for B2B customers, where they can book clients and give on rent their Telebotz</li>
                        <li>A dedicated webapp powered by CROBOT Technologies for B2C model</li>
                        <li>Complete backend data and internet bandwidth maintenance on a nominal monthly subscription</li>
                    </ol> */}
                </div>
            </div>
            </div>
    );
    }

    export default AboutUs
import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import SideBar from './SideBar';

import './ModernDashboard.css'

const ModernDashboard = ({ currentUser, getUserDetails, roboList, getLinkedRobots }) => {
  
  const [fetchedDetails, setFetchedDetails] = useState(null)

  const reloadUser = async () => {
    const [userDetails, roboArray] = fetchedDetails;
    console.log('Into reload user');
    await getUserDetails(userDetails.id, userDetails.token);
    // console.log(roboList)
    await getLinkedRobots(userDetails.id);
    setFetchedDetails([currentUser, roboList]);
    console.log('Fetched details');
    console.log(fetchedDetails);
  }
  
  useEffect(() => {
		const funcEx = async () => {
			// console.log(`Current user is ${currentUser}`);
			await getUserDetails(currentUser.id, currentUser.token);
			await getLinkedRobots(currentUser.id);
      // console.log('After get user details');
      // console.log(currentUser, roboList);
      setFetchedDetails([currentUser, roboList]);
      // console.log(fetchedDetails);
		}
		funcEx();
    console.log("in loop")
	}, [currentUser.name]);

  return (
    <div className='fl-col-c-c mod-dash-container'>
      <SideBar sentUser={JSON.stringify(currentUser)} getUserDetails={getUserDetails}/>
      <div className="mod-dash-right">
        <Outlet context={[fetchedDetails, setFetchedDetails, reloadUser]} />
      </div>
    </div>
  )
}

export default ModernDashboard
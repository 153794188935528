import React, { useState, useEffect } from "react";
import { useKeyPressEvent } from "react-use";
import {
  FaArrowUp,
  FaArrowLeft,
  FaArrowRight,
  FaArrowDown,
  FaStop,
  FaCamera
} from "react-icons/fa";

import "../../pages/RoboDashboard/roboDashboard.css";
import './DashCustom.css'
import { useAuth } from "../../context/AuthContext";
import { useOutletContext } from "react-router-dom";

import operate from '../../assets/operate_page.svg'

const Control = ({ onClose, onLeft, onRight, onUp, onDown, cameraUp, cameraDown, ws }) => {
  useKeyPressEvent("Escape", onClose);
  useKeyPressEvent("b", onClose);
  useKeyPressEvent("a", onLeft);
  useKeyPressEvent("d", onRight);
  useKeyPressEvent("w", onUp);
  useKeyPressEvent("s", onDown);
  useKeyPressEvent("q", cameraUp);
  useKeyPressEvent("e", cameraDown);

  return (
    <div className="  ">
      <div className="video-control-control">
        <button onClick={onClose}>
          <FaStop />
        </button>
        <button onClick={() => ws.send(JSON.stringify({
          action: "message",
          data: "forward" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
        }))}>
          <FaArrowUp />
        </button>
        <button onClick={() => ws.send(JSON.stringify({
          action: "message",
          data: "left" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
        }))}>
          <FaArrowLeft />
        </button>
        <button onClick={() => ws.send(JSON.stringify({
          action: "message",
          data: "right" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
        }))}>
          <FaArrowRight />
        </button>
        <button onClick={() => ws.send(JSON.stringify({
          action: "message",
          data: "backward" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
        }))}>
          <FaArrowDown />
        </button>
      </div>
      <div className="camera-control-control">
        <button onClick={cameraUp}><FaArrowUp /></button>
        <FaCamera />
        <button onClick={cameraDown}><FaArrowDown /></button>
      </div>
    </div>
  );
};

const DashOperate = () => {

  const ws = new WebSocket(
    "wss://7udtkx6tq5.execute-api.us-east-1.amazonaws.com/production?name=mahaarun"
  );

  const apiCallRight = {
    action: "message",
    data: "right" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };

  const apiCallLeft = {
    action: "message",
    data: "left" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };

  const apiCallForward = {
    action: "message",
    data: "forward" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };

  const apiCallBackward = {
    action: "message",
    data: "backward" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };

  const apiCallStop = {
    action: "message",
    data: "stop" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };

  const apiCallConnect = {
    action: "message",
    data: "connect" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };
  
  const apiCallCameraUp = {
    action: "message",
    data: "cameraUp" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };
  const apiCallCameraDown = {
    action: "message",
    data: "cameraDown" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
  };

  ws.onopen = (event) => {
    // ws.send(JSON.stringify(apiCall));
    console.log("Connected to server");
  };

  ws.onmessage = function (event) {
    const json = JSON.parse(event.data);
    console.log(json);
    try {
      if ((json.event = "data")) {
        // setBids(json.data.bids.slice(0, 5));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [isConnected, setConnected] = useState(false);
  const [liveData, setLiveData] = useState("");
  const [roboId, setRoboId] = useState(null);

  const { currentUser, roboList, getLinkedRobots } = useAuth()
  const { fetchedDetails } = useOutletContext()

  // useEffect(() => {
  //   console.log(liveData, roboId);
  //   getLinkedRobots(currentUser.id)
  // });

  useEffect(() => {
    const funcEx = async () => {
      console.log(`Current user is`)
      console.log(currentUser)
      await getLinkedRobots(currentUser.id)
    }
    console.log(liveData, roboId)
    funcEx()
    console.log(roboList)
  }, [])

  const changeData = (e) => {
    const inputData = e.target.value;
    setLiveData(inputData);
  };

  const handleChange = (e) => {
    setLiveData(e.target.value)
  }

  return (
    <div className="row-center-even Robo">
      {(currentUser.subscriptionStatus !== 0) ?
        (!isConnected ? (
          <>
            <div className="fl-col-c-c dash-operate-container">
              <div className="robocontrol-heading">
                Operate your robot
              </div>
              <div className="fl-col-c-c dash-operate-operation">
                <img src={operate} alt="Operate bot" id='operate-img' />
                <div className="fl-col-c-c dash-operate-list-container">
                  <select className="dash-operate-list-dropdown" onChange={handleChange}>
                    <option value={""}> -- Select a robot --</option>
                    {roboList.map((el) => {
                      console.log(el)
                      const data = el.roboid
                      return (
                        <option value={`${data}`} className="robocontrol-robots">{data}</option>
                      )
                    })}
                  </select>
                  <div className="data-submit">
                    {liveData !== "" && <button
                      type="submit"
                      onClick={(e) => {
                        ws.send(JSON.stringify(apiCallConnect));
                        e.preventDefault();
                        setRoboId(liveData);
                        setConnected(true);
                      }}
                    >
                      Connect!
                    </button>}
                  </div>
                </div>
              </div>
              {/* <div className="row-center-even robocontrol-data-field">
              <div className="data-input">
                  <input
                    type="text"
                    name=""
                    id="roboData"
                    onChange={changeData}
                  />
                </div>
              <div className="data-submit">
                {liveData !== "" && <button
                  type="submit"
                  onClick={(e) => {
                    ws.send(JSON.stringify(apiCall4));
                    e.preventDefault();
                    setRoboId(liveData);
                    setConnected(true);
                  }}
                >
                  Connect!
                </button>}
              </div>
            </div>*/}
            </div>
          </>
        ) : (
          <div className="fl-col-c-c video-control-container dash-operate-video-container">
            <div className="video-control-meet">
              <iframe
                allow="camera; microphone; fullscreen; speaker; display-capture"
                src="https://crobottech.yourvideo.live/626d23f1f183f8570398a4f9">
              </iframe>
              <Control
                onClose={() => ws.send(JSON.stringify(apiCallStop))}
                onLeft={() => ws.send(JSON.stringify(apiCallLeft))}
                onRight={() => ws.send(JSON.stringify(apiCallRight))}
                onUp={() => ws.send(JSON.stringify(apiCallForward))}
                onDown={() => ws.send(JSON.stringify(apiCallBackward))}
                cameraUp={() => ws.send(JSON.stringify(apiCallCameraUp))}
                cameraDown={() => ws.send(JSON.stringify(apiCallCameraDown))}
                ws={ws}
              />
            </div>
          </div>
        ))
        :
        (
          <div style={{ fontSize: '1.3rem' }}>
            <span style={{ color: 'red' }}>No active subscription</span> plans, please purchase one to operate the robot!
          </div>
        )}
    </div>
  )
}
{/* <Modal onClose={() => ws.send(JSON.stringify(apiCall3))} />
  <Modal onLeft={() => ws.send(JSON.stringify(apiCall1))} />
  <Modal onRight={() => ws.send(JSON.stringify(apiCall))} />
  <Modal onUp={() => ws.send(JSON.stringify(apiCall2))} />
  <Modal onDown={() => ws.send(JSON.stringify(apiCall3))} /> */}

export default DashOperate

// const Modal = function ({ onClose, onLeft, onRight, onUp, onDown, onEnter }) {
// 	useKeyPressEvent("Escape", onClose);
// 	useKeyPressEvent("a", onLeft);
// 	useKeyPressEvent("d", onRight);
// 	useKeyPressEvent("w", onUp);
// 	useKeyPressEvent("s", onDown);

// 	return (
// 		// <ModalBackdrop>
// 		// 	<ModalContent>
// 		// 		<>
// 		// 			<button onClick={onClose}>Stop</button>
// 		// 			<button onClick={onLeft}>Left</button>
// 		// 			<button onClick={onRight}>Right</button>
// 		// 			<button onClick={onUp}>Up</button>
// 		// 			<button onClick={onDown}>Down</button>
// 		// 		</>
// 		// 	</ModalContent>
// 		// </ModalBackdrop>
// 		<>Control goes here</>
// 	);
// };

// function App() {
// 	//give an initial state so that the data won't be undefined at start
// 	// const [bids, setBids] = useState([0]);

// 	const ws = new WebSocket(
// 		"wss://7udtkx6tq5.execute-api.us-east-1.amazonaws.com/production?name=mahaarun"
// 	);

// 	const apiCall = {
// 		action: "message",
// 		data: "right",
// 	};

// 	ws.onopen = (event) => {
// 		ws.send(JSON.stringify(apiCall));
// 		console.log("Connected to server");
// 	};

// 	ws.onmessage = function (event) {
// 		const json = JSON.parse(event.data);
// 		console.log(json);
// 		try {
// 			if ((json.event = "data")) {
// 				// setBids(json.data.bids.slice(0, 5));
// 			}
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	};
// 	//map the first 5 bids
// 	// const firstBids = bids.map((item) => {
// 	// 	return (
// 	// 		<div>
// 	// 			<p> {item}</p>
// 	// 		</div>
// 	// 	);
// 	// });

// 	return <div>Helloworld</div>;
// }

// export default App;

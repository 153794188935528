export const HeaderItems = [
  {
    title: "Products",
    url: "/product",
    cName: "nav-links",
  },
  {
    title: "About Us",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "Contact Us",
    url: "/contact",
    cName: "nav-links",
  }
];

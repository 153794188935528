import * as React from 'react';
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { Avatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';

import ProfilePhoto from '../../assets/userprof/man.png'

// import { useAuth } from '../../context/AuthContext';

import './DashCustom.css'

const BasicCard = ({ heading, description, number, fetchedDetails, reloadUser }) => {

  const [userDetails, roboArray] = fetchedDetails

  // const reloadUser = async () => {
  //   console.log('Into reload user')
  //   await getUserDetails(userDetails.id, userDetails.token)
  //   await getLinkedRobots(userDetails.id)
  //   setFetchedDetails(fetchedDetails)
  //   console.log('Fetched details')
  //   console.log(fetchedDetails)
  // }

  return (
    <Card className='fl-col-c-c' sx={{ maxWidth: '100%', minHeight: 220, backgroundColor: 'var(--tertiary-color)', color: 'var(--supplementary-color)', border: 0, boxShadow: 0 }} style={{ borderRadius: '15px' }}>
      <CardContent>
        <Typography variant="h4" component="div" color='var(--primary-color)'>
          {heading}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="var(--supplementary-color)">
          {description}
        </Typography>
        <Typography variant="h4">
          {roboArray.length}
        </Typography>
        <CardActions style={{ justifyContent: 'center', paddingBottom: '0px' }}>
          <Button size="normal" style={{ minWidth: 'min-content' }} endIcon={<RefreshIcon />} onClick={reloadUser} >Refresh</Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

const ProfileCard = ({ name }) => {
  return (
    <Card sx={{ maxWidth: '20%', minHeight: 220, backgroundColor: 'var(--supplementary-color)', color: 'var(--primary-color)', border: 0, boxShadow: 0 }} style={{ borderRadius: '15px' }}>
      <CardContent className='fl-col-c-c' sx={{ gap: '20px' }}>
        <Avatar alt="User" src={ProfilePhoto} sx={{ width: 120, height: 120 }} />
        <Typography variant="h4" >
          {name}
        </Typography>
      </CardContent>
    </Card>
  )
}

const SubscriptionCard = ({ user }) => {

  const status = user.subscriptionStatus;

  if (!status) {
    return (
      <Card className='fl-col-c-c' sx={{ minWidth: '80%', minHeight: 400 }} style={{ borderRadius: '15px', justifyContent: 'space-evenly' }}>
        <CardContent className='fl-col-c-c' style={{ height: 300, justifyContent: 'space-evenly' }}>
          <Typography variant="h5" component="div" style={{ color: 'red' }}>
            No Active Plan
          </Typography>
          <Typography variant="h6" style={{ textAlign: 'justify', maxWidth: '80%', textAlignLast: 'center' }} >
            No active subscriptions, please visit subscriptions page to look for subscription plans. You can also enquire about the same from the contact page.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  else {
    return (
      <Card className='fl-col-c-c' sx={{ minWidth: '80%', minHeight: 200 }} style={{ borderRadius: '15px' }}>
        <CardContent className='fl-col-c-c' sx={{ height: '100%', justifyContent: 'space-evenly' }} style={{justifyContent: 'space-evenly', fontFamily: 'Rubik'}}>
          <Typography variant="h5" component="div" style={{color: 'green'}}>
            Active Plans
          </Typography>
          <Typography variant="h6" style={{marginTop: '1rem'}} >
            Your plan "Rent for a period expires in 40 days"
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

const DashDefault = () => {

  const [fetchedDetails, setFetchedDetails, reloadUser] = useOutletContext()

  useEffect(() => {
    reloadUser()
  }, [])

  if (fetchedDetails !== null) {

    const [userDetails, roboArray] = fetchedDetails

    return (
      <div className='proper-div dash-default-container'>
        <div className="dash-default-heading">DASHBOARD</div>
        <div className="proper-div dash-default-inner-container">
          <div className="dash-default-inner-container-heading">Robots Connected</div>
          <div className="proper-div dash-default-firstcontainer-card-container">
            <div><BasicCard heading={"Active"} number={0} description={"Online"} fetchedDetails={fetchedDetails} setFetchedDetails={setFetchedDetails} reloadUser={reloadUser} /></div>
            <div><BasicCard heading={"Linked"} number={2} description={"Total"} fetchedDetails={fetchedDetails} setFetchedDetails={setFetchedDetails} reloadUser={reloadUser} /></div>
          </div>
        </div>
        <div className="proper-div dash-default-inner-container">
          <div className="dash-default-inner-container-heading">Subscription Status</div>
          {/* <SubscripCard fetchedDetails={userDetails} /> */}
          <SubscriptionCard user={userDetails} />
        </div>
      </div>
    )
  }
}

export default DashDefault
import React, { useState, useEffect } from 'react'
import './DashCustom.css'

import { useAuth } from '../../context/AuthContext'

const DashProfile = () => {

  const [localError, setLocalError] = useState(null);

  const { currentUser, getUserDetails, updateProfile, errorContext } = useAuth()

  const [details, setDetails] = useState({
    id: null,
    name: currentUser.name || null,
    mobileNumber: currentUser.mobileNumber || null,
    address: currentUser.address || null
  });

  const submitDetails = async (e) => {
    e.preventDefault();
    const token = currentUser.token;
    const userId = currentUser.id;
    console.log(token, userId);
    setDetails((prev) => ({ ...prev, id: userId }));
    if (details.address !== "" && details.mobileNumber !== "" && details.name !== "") {
      await updateProfile(details, token).then((response) => {
        console.log(response);
        setLocalError(null);
      });
    }
    else {
      setLocalError("Do not leave any input boxes empty, fill in all of the details!")
    }
    console.log(details);
  }

  useEffect(() => {
    const funcEx = async () => {
			await getUserDetails(currentUser.id, currentUser.token);
		}
		funcEx();
    setDetails((prev) => ({...prev, id: currentUser.id}))
    console.log(details);
  }, [])

  return (
    <div className='dashprof-wrapper'>
      <div className="dashprof-heading">
        PROFILE
      </div>
      <div className="dashprof-form">
        <div className="dashprof-fields">
          <div className='dashprof-indi'>Name :</div>
          <input type="text" defaultValue={currentUser.name} placeholder={currentUser.name} onChange={(e) => {
            const data = e.target.value;
            setDetails((prev) => ({ ...prev, name: data }))
          }} />
        </div>
        {/* <div className="dashprof-fields">
          <div className='dashprof-indi'>Email ID :</div>
          <input type="text" onChange={(e) => {
            const data = e.target.value;
            setDetails(details.emailId=data)
          }}/>
        </div> */}
        <div className="dashprof-fields">
          <div className='dashprof-indi'>Mobile Number :</div>
          <input type="text" defaultValue={currentUser.mobileNumber} placeholder={currentUser.mobileNumber} onChange={(e) => {
            const data = e.target.value;
            setDetails((prev) => ({ ...prev, mobileNumber: data }))
          }} />
        </div>
        <div className="dashprof-fields">
          <div className='dashprof-indi'>Address :</div>
          <input type="text" defaultValue={currentUser.address} placeholder={currentUser.address} onChange={(e) => {
            const data = e.target.value;
            setDetails((prev) => ({ ...prev, address: data }))
          }} />
        </div>
        <span style={{ color: 'red', fontSize: '1.5rem' }}>{errorContext || localError}</span>
        <button className='profile-submit' onClick={submitDetails}>
          Update Profile
        </button>
      </div>
    </div>
  )
}

export default DashProfile
import React from 'react'

import teamdisc from '../../assets/team_images/teamdisc.jpg'
import work1 from '../../assets/team_images/work1.webp'
import nsm from '../../assets/team_images/nsm.webp'
import gp from '../../assets/team_images/gp.webp'
import rln from '../../assets/team_images/rln.webp'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import './About.css'

const About = () => {
  return (
    <>
      <Header />
      <div className="fl-col-c-c aboutus__container">
        <div className="aboutus__overall__heading">
          <h1>CROBOT TECHNOLOGIES PRIVATE LIMITED</h1>
        </div>
        <div className="fl-col-c-c aboutus__inner__container">
          <img src={work1} alt="Image goes here" className='container__image' id='first-about-image' />
          <div className="container__text">
            <div>Who are we?</div>
            We are a team of passionate engineers and innovators who are dedicated to using robotics to solve real-world problems. We believe that robotics has the potential to revolutionize manufacturing and make our lives easier, and we are excited to be a part of that revolution. As you can see from the conversation between our founder and employee, we are a company that is built on collaboration and creativity. We are always looking for new ways to improve our products and services, and we are always open to feedback from our customers. We believe that by working together, we can make a real difference in the world.
          </div>
        </div>
        <div className="aboutus__meetourteam__heading">
          <h3>Meet Our Team</h3>
        </div>
        <div className="fl-col-c-c aboutus__meetourteam__container">
          <div className="fl-col-c-c aboutus__meetourteam__card">
            <img src={nsm} alt="Dr. Manigandan N S" className="aboutus__meetourteam__image" />
            <div className="fl-col-c-c aboutus__meetourteam__text">
              <h3>Dr. MANIGANDAN N S</h3>
              <span>Founder & Director</span>
              <p>Manigandan started his career as a teacher and has been teaching Electronics, Instrumentation and Robotics for the past 27 years. The passion to become an entrepreneur culminated during covid time while working on developing solutions to provide remote assistance for afflicted persons. He has wide experience in hardware design, development of mobile robots and has worked on SLAM implementation for autonomous navigation.</p>
            </div>
          </div>
          <div className="fl-col-c-c aboutus__meetourteam__card">
            <img src={''} alt="Dr. Manigandan N S" className="aboutus__meetourteam__image" style={{backgroundColor: "black"}} />
            <div className="fl-col-c-c aboutus__meetourteam__text">
              <h3>Mrs. Kala</h3>
              <span>Co-founder & Finance Officer</span>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed enim recusandae saepe! Ab, porro quos! Ut ab quasi sed amet sit temporibus aut cumque. Porro vel quis quibusdam! Saepe quae nobis optio facere nihil quam illum vero.</p>
            </div>
          </div>
          <div className="fl-col-c-c aboutus__meetourteam__card meetourteamcard__reverse">
            <img src={rln} alt="Lakshmi Narasimhan" className="aboutus__meetourteam__image" />
            <div className="fl-col-c-c aboutus__meetourteam__text">
              <h3>Mr. Narasimhan R L</h3>
              <span>Co-Founder</span>
              <p>He is the Co-founder with an experience of more than 18 years in the IT service delivery department. He is an expert in creating and upscaling new platforms and has strong visions for CROBOT Technologies Pvt. Ltd.</p>
            </div>
          </div>
          <div className="fl-col-c-c aboutus__meetourteam__card">
            <img src={gp} alt="Guru Pirasath" className="aboutus__meetourteam__image" />
            <div className="fl-col-c-c aboutus__meetourteam__text">
              <h3>Mr. Gurupirasath S E</h3>
              <span>Technical Engineer</span>
              <p>Gurupirasath holds a Master's degree in Robotics and Artificial Intelligence. He is focussed on design and development of various mobile robots. He specialises in CAD designing, modelling and ROS stack development.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
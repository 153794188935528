import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const [userdetails, setUserdetails] = useState({
    isAuthenticated: localStorage.getItem("crobot") || false,
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [roboList, setRoboList] = useState([]);
  const [errorContext, setErrorContext] = useState(null);

  const navigate = useNavigate();

  const signUp = async (username, email, password, confirmPassword) => {
    if (password === confirmPassword) {
      try {
        await axios
          .post(`${process.env.REACT_APP_API}/api/users/`, {
            Username: username,
            email: email,
            password: password,
          })
          .then((data) => {
            console.log(data);
            const token = data.token;
          });
        setErrorContext(null);
      } catch (error) {
        setErrorContext(error);
        console.log(error);
      }
    }
  };

  const logIn = async (email, password) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API}/api/users/login`, {
          "email": email,
          "password": password,
        })
        .then((data) => {
          const response = data.data;
          if(response.success != 0) {
            const userObject = {
              id: response.details.id,
              type: response.details.type,
              username: response.details.username,
              email: response.details.email,
              type: response.details.type,
              subscriptionStatus: response.details.subscriptionStatus,
              createdAt: response.details.createdAt,
              name: response.details.name,
              token: response.token
            }
            setCurrentUser(userObject);
            setErrorContext(null);
            sessionStorage.setItem("crobot", JSON.stringify(userObject));
          }
          else {
            setErrorContext(response.data);
            setCurrentUser(null);
          }
        });
    } catch (error) {
      setCurrentUser(null);
      setErrorContext(error);
    }
  };

  const logout = () => {
    sessionStorage.clear();
    setCurrentUser(null);
    setErrorContext(null);
  };

  const getUserDetails = async (id, token) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/api/users/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          const data = response.data
          if(data.success == 1) {
            const userObject = {
              id: data.details.id,
              type: data.details.type,
              username: data.details.username,
              email: data.details.email,
              subscriptionStatus: data.details.subscriptionStatus,
              createdAt: data.details.createdAt,
              name: data.details.name,
              address: data.details.address,
              mobileNumber: data.details.mobileNumber,
              token: token
            }
            setCurrentUser(userObject);
            setErrorContext(null);
          }
          else {
            setErrorContext(response.message)
            setCurrentUser(null)
          }
          // const retobj = {
          //   username: data.data.username,
          //   email: data.data.email,
          //   id: id
          // }
          // return retobj
        })
    } catch (error) {
      setErrorContext(error)
    }
  }

  const getLinkedRobots = async (id) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/api/robo/linked/${id}`)
        .then((response) => {
          const data = response.data
          // console.log(data)
          if(data.success === 1) {
            // console.log(roboList)
            const roboArray = data.robos
            setRoboList(roboArray)
            // console.log(roboList)
            setErrorContext(null)
          }
        })
    } catch (error) {
      // console.log(error)
      setErrorContext(error)
    }
  }

  const updateProfile = async (data, token) => {
    try {
      await axios
        .patch(`${process.env.REACT_APP_API}/api/users/updateUser`, data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          const data = response.data;
          console.log(data);
          if(data.success == 1) {
            setErrorContext(null);
            return data;
          }
          else {
            setErrorContext(data.message);
          }
        })
    } catch (error) {
      console.log(error);
      setErrorContext(error);
    }
  }

  const putContactQuery = async (data) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API}/api/contact/`, data)
        .then((res) => {
          const data = res.data;
          console.log(data);
          if(data.success === 1) {
            setErrorContext(null);
            return data.message;
          }
          else {
            setErrorContext(data.message);
          }
        })
    } catch (error) {
      console.log(error);
      setErrorContext(error);
    }
  }

  // useEffect(() => {
  //   setCurrlocalStorage.getItem("crobot");
  // }, [currentUser]);

  return (
    // <AuthContext.Provider
    //   value={{ userdetails, currentUser, setUserdetails, logIn, logout, signUp, refreshAcc }}
    // >
    <AuthContext.Provider value={{ logIn, signUp, currentUser, logout, getUserDetails, getLinkedRobots, updateProfile, putContactQuery, roboList, errorContext }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React, { useState } from 'react'
import './Chatsection.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const Chatsection = () => {

  const [q1, setQ1] = useState(false);
  const [q2, setQ2] = useState(false);
  const [q3, setQ3] = useState(false);

  return (
    <div className='fl-col-c-c chat-container'>
      <div className="chat-heading">
        Chat with the Founder!
      </div>
      <div className="fl-col-c-c chat-question-container">
        <div className="fl-col-c-c chat-card">
          <div className="fl-col-c-c chat-question">
            Hello Mani! What do you feel, is the role of robots in human life?
            {q1 ?
              <FontAwesomeIcon className='chat-icons' icon={faArrowUp} onClick={() => setQ1(false)} />
              :
              <FontAwesomeIcon className='chat-icons' icon={faArrowDown} onClick={() => setQ1(true)} />
            }
          </div>
          {q1 &&
            <div className="chat-answer">
              Currently, the pace at which technology evolves, the work culture of people has become more skill based rather monotonous. This needs to be appreciated as it indicates the growth and mindset of any individual that collectively determines the growth of the society at large. Given that it becomes necessary to identify an alternative solution that can perform these non-skilled jobs. Robots have become an immediate alternative for such tasks. We should understand that the robots are more of assistive to human rather replacement.
            </div>}
        </div>
        <div className="fl-col-c-c chat-card">
          <div className="fl-col-c-c chat-question">
            UBot is one of the products listed, can you elaborate on its application?
            {q2 ?
              <FontAwesomeIcon className='chat-icons' icon={faArrowUp} onClick={() => setQ2(false)} />
              :
              <FontAwesomeIcon className='chat-icons' icon={faArrowDown} onClick={() => setQ2(true)} />
            }
          </div>
          {q2 &&
            <div className="chat-answer">
              UBot is a “User Bot”, meaning the user can control the mobile robot through an application in their mobile phone / laptop from anywhere in the world, provided a good internet connectivity is available. The UBot has numerous features that enables a list of applications starting from people assistance to industrial monitoring. Basically, you can see this as an enhanced audio / video communication system with semi-autonomous and autonomous capability.
              <br />
              One best use case I can suggest is in a big shopping mall / hyper mall, where it can effectively replace the CCTV cameras. This will largely bring down the installation cost, maintenance cost, eliminate blind spots, and readily deployable. Any advertisement by shopkeepers and announcement by security personals can be done through this UBot. The customers can make advance orders in the food court through these UBots while entering the mall, thus reducing the waiting time.
            </div>}
        </div>
        <div className="fl-col-c-c chat-card">
          <div className="fl-col-c-c chat-question">
            Does CROBOT has any other products in line?
            {q3 ?
              <FontAwesomeIcon className='chat-icons' icon={faArrowUp} onClick={() => setQ3(false)} />
              :
              <FontAwesomeIcon className='chat-icons' icon={faArrowDown} onClick={() => setQ3(true)} />
            }
          </div>
          {q3 &&
            <div className="chat-answer">
              Yes, we have plans to introduce robot for house maintenance.
            </div>}
        </div>
      </div>
    </div>
  )
}

export default Chatsection
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import UpcomingComponent from "./components/UpcomingComponent";

function UpcomingEvents(){
    return (
        <>
            <Header />
            <UpcomingComponent />
            <Footer />
        </>
    );
}
export default UpcomingEvents;
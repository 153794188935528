import React, { useState } from 'react'
import { HeaderItems } from "./HeaderItems";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import ct from "../../icons/crobot2.png";

// import "./NavbarStyles.css";
import "./headernew.css"

const Header = ({ transparent = false }) => {

  const [clicked, setClicked] = useState(false)

  // document.addEventListener('scroll', () => {
  //   let target = document.getElementById('trans-nav')
  //   const present = window.location.pathname
  //   if (present === "/home") {
  //     if (((window.scrollY / window.innerHeight) * 100) > 90) {
  //       target.style.background = "#eeeeee"
  //     }
  //     else {
  //       target.style.background = "#eeeeee"
  //     }
  //   }
  //   else {
  //     target.style.background = "#eeeeee"
  //   }
  // })

  return (
    // <nav className="NavbarItems navbar" style={{ backgroundColor: transparent ? 'var(--primary-color)' : 'var(--primary-color)' }} id='trans-nav'>
    <nav className="NavbarItems navbar" style={{ backgroundColor: `var(--primary-color)`, color: `var(--teritary-color)`}}>
      <a href="/" id="navbar-logo-link">
        <h3 className="navbar-logo">
          <img src={ct} alt="ct" id="header_ct" />
          <span>CROBOT TECHNOLOGIES PRIVATE LIMITED</span>
          <span>CROBOT TECHNOLOGIES<br /> PRIVATE LIMITED</span>
          {/* <span>CTPL</span> */}
        </h3>
      </a>

      <ul className="nav-menu">
        {HeaderItems.map((item, index) => (
          <li key={index}>
            <Link className={item.cName} to={item.url}>
              {item.title}
            </Link>
          </li>
        ))}
        <button><Link to='/signin'>Sign In</Link></button>
      </ul>
      <div className="menu-icons" onClick={() => setClicked(!clicked)}>
        {clicked ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </div>
      {clicked &&
        (
          <div className="ham-menu">
            {HeaderItems.map((item, index) => (
              <div key={index}>
                <Link className={item.cName} to={item.url}>
                  {item.title}
                </Link>
              </div>
            ))}
            <div><Link to='/signin'>Sign In</Link></div>
          </div>
        )}
    </nav>
  )
}

export default Header